<template>
  <div class="limites_cadastro_background">
    <section class="container section__mb">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            color="white"
            class="conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_violacoes_direito_title_engine.png"
            alt=""
          />
          <h3 class="ml-3">Limites do cadastro corporativo</h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="Os limites do cadastro corporativo: complexidade, duração e a produção da comensurabilidade dos danos"
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Uma leitura crítica sobre o Cadastro Integrado do Programa de
                  Levantamento e Cadastro dos Impactados (PLCI), elaborado pelas
                  empresas Samarco e Synergia Consultoria Ambiental, identificou
                  diversas falhas, deficiências metodológicas e lacunas, as
                  quais foram indicadas e sistematizadas em um
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2017/02/GESTA-UFMG-Parecer-sobre-Cadastro-Integrado-do-PLCI-Samarco-Synergia.pdf"
                    class="pink--text"
                    >parecer</a
                  >
                  produzido pelo
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://gestaprod.lcc.ufmg.br/"
                    class="pink--text"
                    >Grupo de Estudos em Temáticas Ambientais da UFMG<sup
                      >1</sup
                    >
                    em 2016 (GESTA, 2016)</a
                  >. A percepção desses déficits somada à iniciativa da
                  promotoria local e à mobilização intensa dos atingidos
                  possibilitaram a abertura de amplo debate acerca da adequação
                  e da suficiência do cadastro original proposto e já
                  operacionalizado pelas empresas nos demais municípios
                  atingidos. A partir da eleição da assessoria técnica no
                  território de Mariana, esse debate ensejou um longo, oneroso e
                  doloroso processo de revisão e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_contexto_conquistas' }"
                    >reformulação do cadastro</router-link
                  >
                  construído pela Samarco para o levantamento das perdas e danos
                  sofridos pelos atingidos.
                </p>
                <p>
                  É nesse contexto de avaliação e remodelação do cadastro que
                  foi realizada a produção da
                  <a
                    style="text-decoration: none"
                    class="pink--text"
                    target="_blank"
                    href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2020/08/BOLETIM-16-PARACATU-VERSAO-DIGITAL-COMPRIMIDA-12.pdf"
                    >Cartografia Social de Paracatu de Baixo</a
                  >, iniciativa do GESTA juntamente com os atingidos dessa
                  comunidade. Foi, portanto, nessa conjuntura específica de
                  disputas sobre as ferramentas apropriadas e necessárias para a
                  apreensão e, posterior, valoração dos danos que o Grupo
                  desenvolveu a interlocução com os moradores de Paracatu a fim
                  de construir conjuntamente com eles, um mapeamento comunitário
                  dos bens, relações, histórias, práticas e memórias
                  constitutivas do seu antigo território de referência (GESTA,
                  2019).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_violacoes_direito_01.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Audiência do Fórum de Justiça de Mariana em 05/10/2017, ao lado
            direito estavam os representantes e advogados das empresas que não
            permitiam o registro de suas imagens. Fonte: TV Globo, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_violacoes_direito_02.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Oficina de Cartografia Social da Comunidade de Paracatu de Baixo.
            Foto: Acervo Gesta, 2017.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A experiência da cartografia social conduziu a um importante
                  aprendizado relativo à natureza e à temporalidade dos danos
                  que o cadastro pretendia detectar. Nessa linha, uma primeira
                  observação se refere à necessidade de efetivo reconhecimento
                  do caráter processual do desastre (Zhouri et al, 2018).
                  Caracterizar o desastre como processo implica admitir que as
                  consequências do rompimento se modificam, se expandem, se
                  agravam, se acumulam e são aprofundadas na medida em que
                  interagem com as intervenções emergenciais e com os próprios
                  instrumentos desenhados para a reparação. Essa leitura
                  invalida a abordagem linear e simplificadora que foi adotada
                  pelas empresas (Vale, Samarco e BHP Billiton) de que a
                  resposta corporativa ao desastre pode se resumir a um elenco
                  de programas mitigadores e compensadores que pretendem incidir
                  sobre os impactos imediatamente derivados do rompimento da
                  barragem. A concepção do desastre como processo tensiona a
                  expectativa de que os danos podem ser rapidamente
                  discriminados, coligidos e tabulados através de instrumento
                  cadastral que fora construído unilateralmente pelas empresas e
                  suas consultorias, sem qualquer tipo de consulta ou diálogo
                  previamente estabelecido com as vítimas.
                </p>
                <p>
                  O caráter disruptivo do desastre, em que as condições de vida
                  das pessoas são alteradas de uma maneira muito abrupta, muito
                  profunda e irreversível, levava à ilusão de que os danos eram
                  autoevidentes, que eles estavam imediatamente estampados na
                  natureza dos fatos e que poderiam ser facilmente organizados
                  como um conjunto de intervenções sobre as propriedades e as
                  rendas das vítimas (Zhouri et al, 2017). Tudo se passava como
                  se as alterações dramáticas, profundas e radicais sofridas
                  pelos atingidos pudessem ser devidamente tratadas pelo
                  repertório de programas socioambientais e socioeconômicos
                  conduzidos pela Fundação Renova.
                </p>
                <p>
                  Como sabemos, entretanto, tais procedimentos nunca capazes de
                  traduzir e de responder a contendo à experiência dos danos dos
                  atingidos. Não fortuitamente, em correspondência com o
                  cadastro originalmente desenvolvido pelas empresas, a matriz
                  de danos elaborada pela Fundação Renova se apresentava como um
                  inventário de feição marcadamente patrimonialista.
                </p>
                <p>
                  Isso significa que a apreensão dos danos foi reduzida a um
                  instrumento de serialização das perdas materiais, ignorando as
                  repercussões do desastre sobre bens imateriais, sobre as
                  relações sociais e, sobretudo, sobre as práticas e dinâmicas
                  coletivas que sempre expressaram a territorialidade dos grupos
                  afetados.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Em contraste, quando os atingidos enunciam os danos a partir
                  de sua própria experiência nota-se claramente que suas
                  narrativas interpelam essa leitura corporativa simplificadora
                  do desastre e da reparação. É no bojo desse processo que
                  ganham relevância os esforços das vítimas e da
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_assessoria' }"
                    >assessoria técnica independente</router-link
                  >
                  (Cáritas-MG) em construir instrumentos alternativos e/ou
                  complementares de evidenciação e valoração dos danos. Por essa
                  via, perdas significativas obliteradas através da distinção
                  entre “impacto direto” e “impacto indireto” ganham projeção, a
                  exemplo da desvalorização dos imóveis localizados nas áreas de
                  “dam break” ou as alterações enfrentadas no seu regime de
                  mobilidade a partir da rarefação ou do esvaziamento das opções
                  de transporte e deslocamento em função do esvaziamento das
                  comunidades provisoriamente realocadas na sede urbana de
                  Mariana.
                </p>
                <p>
                  Também permanecem invisíveis na abordagem das empresas a perda
                  dos bens coletivos que constituem referências importantes para
                  a memória e para a construção da identidade dessas
                  comunidades, a exemplo das igrejas, dos cemitérios, dos campos
                  de futebol e de tantos outros locais tradicionais de festejo
                  ou lazer. A perspectiva adotada pela Fundação Renova foi a de
                  que tais bens não seriam passíveis de políticas indenizatórias
                  sendo apenas objeto de reconstrução nas áreas de
                  reassentamento. Tal leitura guarda pressupõe que a
                  recomposição material das estruturas é medida suficiente para
                  reparar a perda desses bens comunitários, como se tais
                  referências pudessem ser reduzidas à restituição de
                  edificações, ignorando o significado que elas assumem no
                  conjunto das relações sociais que eram mobilizadas ou que se
                  organizavam através do seu uso.
                </p>
                <p>
                  A relevância desses bens comunitários foi bastante evidenciada
                  durante o processo de construção da cartografia social de
                  Paracatu de Baixo (GESTA, 2019). As narrativas, as lembranças
                  e as reflexões dos atingidos mostram que a indenização dos
                  danos socioculturais pela perda desses elementos não se
                  confunde com a reconstrução dessas edificações. É preciso
                  considerar que tais bens constituem balizas fundamentais na
                  trajetória dos grupos e, são, nessa medida, elementos que
                  organizam e mobilizam a memória coletiva, que investem de
                  sentido e de afeto, dezenas de práticas rituais, estruturando,
                  desse modo, formas de transmissão de conhecimento. Destarte, o
                  que está em jogo, portanto, não é apenas o dano sobre as
                  propriedades ou rendas. Afinal, são transformações que incidem
                  sobre as relações sociais que gravitam, se organizam e que,
                  antes de tudo, se perpetuam através da interação com tais
                  bens.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <span
            @click="showOverlayer.fig1 = !showOverlayer.fig1"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" :value="showOverlayer.fig1">
            <img
              max-width="60%"
              src="../../assets/conteudos_violacoes_direito_03.png"
              alt=""
            />

            <v-btn
              class="mx-auto"
              small
              color="#E06919"
              @click="showOverlayer.fig1 = false"
            >
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            src="../../assets/conteudos_violacoes_direito_03.png"
            alt=""
          />

          <p style="font-size: 0.6em; text-align: center;">
            Detalhe da Cartografia Comunitária de Paracatu de Baixo. Fonte:
            GESTA, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Reflexão semelhante pode ser desenvolvida acerca dos conflitos
                  relativos à viabilidade dos reassentamentos coletivos. Nessa
                  seara, são significativas as disputas no tocante às condições
                  para o uso da água no terreno da Lucila, área onde será
                  reassentada a comunidade de Paracatu de Baixo (OLIVEIRA et al,
                  2020). Essa matéria tem sido tratada pela Fundação Renova como
                  uma questão relativa à “disponibilidade hídrica”. Ou seja, a
                  pergunta central que orienta o planejamento da realocação
                  nesse tópico é: “há água suficiente na área para o
                  reassentamento de Paracatu?”. Com efeito, a atuação das
                  empresas está centrada em um cálculo sobre o consumo médio de
                  água de cada família. No entanto, quando interagimos com os
                  moradores de Paracatu de Baixo durante a produção da
                  cartografia social restou muito claro que não se trata apenas
                  da quantidade de água disponível. Para além das questões da
                  qualidade e da acessibilidade desse recurso é preciso
                  considerar que os saberes e práticas locais sempre valorizaram
                  os qualificativos de uma água “sadia”, “farta”, “coletiva” e,
                  sobretudo, “livre” (GESTA, 2019). Não se trata de qualquer
                  água. A água das nascentes, por exemplo, que era muito
                  abundante no terreno antigo de Paracatu, mas que é limitada,
                  por exemplo, no terreno da Lucila, sempre foi exaltada e
                  valorizada pela comunidade, comparativamente às águas dos
                  córregos.
                </p>
                <p>
                  Na antiga Paracatu, pelo que foi possível aprender com os
                  moradores, as águas das nascentes se tornavam disponíveis para
                  todos os moradores através de um sistema de represamento,
                  canalização e armazenamento que foi construído e que era
                  mantido pelos próprios núcleos de vizinhança. Havia então
                  esforços de cooperação, ajuda mútua e obrigações
                  compartilhadas que convocavam os moradores a participarem
                  conjuntamente na gestão da água que assumia para o grupo uma
                  dimensão relacional não redutível à mera expressão
                  quantitativa de reposição em volume.
                </p>
                <p>
                  Os atingidos de Paracatu ensinaram nesse processo de
                  interlocução que a água só se torna um bem acessível para a
                  coletividade a partir de uma rede de relações que domina de
                  forma autônoma o manejo dos recursos naturais do território. É
                  o conhecimento dos moradores e o controle que eles tinham
                  sobre esse sistema o que garantia à comunidade a soberania na
                  gestão da gestão. Esse exemplo mostra que a reparação dos
                  danos não se resume a uma quantidade a ser restituída. Há
                  gradações de pureza, usos diferenciados, valorização da
                  autonomia além de aspectos da sociabilidade implicados e que
                  revelam que a relação com a água supera muito o aspecto
                  meramente utilitário. Quanto ao rio, por exemplo, muitas
                  famílias expressam o ressentimento da perda, dada a estima
                  dessa relação como um momento de fruição, de descanso e de
                  lazer. Sem o rio e com o acesso futuramente mediado pelo
                  mercado, é possível que a relação com a água no Novo Paracatu
                  seja uma relação muito distinta e tais transformação não pode
                  ser deixadas à margem do sistema de reparação.
                </p>
                <p>
                  Considerar integralmente os danos exige reconhecer que
                  "sadia”, “farta”, “coletiva” e “livre" são qualidades
                  importantes que foram usurpadas dos atingidos, não são
                  elementos secundários ou residuais no cálculo das
                  indenizações.
                </p>
                <p style="text-align: center;">
                  <strong
                    >Autora: Raquel Oliveira,GESTA/UFMG<sup>2</sup>.</strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 section__mb">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Desde o início de 2016, o
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://gestaprod.lcc.ufmg.br/"
                class="pink--text"
                >GESTA</a
              >
              acompanhou e examinou as diversas medidas e encaminhamentos
              institucionais organizados para responder à crise representada
              pelo desastre do rompimento da Barragem de Fundão. No âmbito dessa
              atuação, o núcleo já produziu artigos científicos, relatórios
              técnicos, além do Boletim de Cartografia Social dedicado à
              comunidade de Paracatu de Baixo.
            </p>
            <p>
              <sup>2</sup> Doutora pelo programa de Pós-Graduação em Sociologia
              da UFMG, Mestra em Sociologia e graduada em Ciências Sociais pela
              UFMG (2005). Professora adjunta do Departamento de Sociologia
              (UFMG), subcoordenadora do Grupo de Estudos em Temáticas
              Ambientais da UFMG (GESTA-UFMG).A autora agradece ao apoio da
              FAPEMIG e do CNPq pelos auxílios que permitiram a execução das
              atividades de pesquisa e extensão universitária junto ao atingidos
              pela Barragem de Fundão em Mariana-MG.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 section__mb">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong> Referências Bibliográficas </strong></p>
            <p>
              GESTA. Parecer sobre o Cadastro Integrado do Programa de
              Levantamento e Cadastro dos Impactados (PLCI) elaborado pelas
              empresas Samarco e Synergia Consultoria Ambiental. Parecer
              Técnico. 2016. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2017/02/GESTA-UFMG-Parecer-sobre-Cadastro-Integrado-do-PLCI-Samarco-Synergia.pdf"
                class="pink--text"
                >https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2017/02/GESTA-UFMG-Parecer-sobre-Cadastro-Integrado-do-PLCI-Samarco-Synergia.pdf</a
              >. Acesso em: 09/02/2022.
            </p>
            <p>
              GESTA. Cartografia Comunitária de Paracatu de Baixo: múltiplos
              danos do rompimento da Barragem de Fundão, Mariana, Minas Gerais.
              Boletim Informativo 16, Cartografia da Cartografia Social: uma
              síntese das experiências, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2020/08/BOLETIM-16-PARACATU-VERSAO-DIGITAL-COMPRIMIDA-12.pdf"
                class="pink--text"
                >https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2020/08/BOLETIM-16-PARACATU-VERSAO-DIGITAL-COMPRIMIDA-12.pdf</a
              >. Acesso em 09/02/2022.
            </p>
            <p>
              OLIVEIRA, Raquel. ZHOURI, Andréa. LOPO, Rafael M. e LIMA,
              Maryellen M. Normas Técnicas, cálculos (in)críveis e a incerteza
              irredutível: equivalências e suficiências contestadas na reparação
              de um desastre. In. SANT’ANA JÚNIOR, H. & RIGOTTO, R. M. (Orgs.)
              Ninguém bebe minério: águas e povos versus mineração. Rio de
              Janeiro: 7Letras, 2020, pp. 74-104.
            </p>
            <p>
              ZHOURI, Andréa; OLIVEIRA, Raquel; ZUCARELLI, Marcos e VASCONCELOS,
              Max. O desastre do Rio Doce: entre as políticas de reparação e a
              gestão das afetações. In. ZHOURI, A. et al. (Orgs.) Mineração,
              Violências e Resistências: um campo aberto à produção de
              conhecimento no Brasil. Marabá: Ed. iGuana, ABA, 2018, pp. 29-65.
              Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2018/03/EBOOK_MineracaoViolenciaResistencia1.pdf"
                class="pink--text"
                >https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2018/03/EBOOK_MineracaoViolenciaResistencia1.pdf</a
              >. Acesso em 09/02/2022.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_desconsideracao_cadastro' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Desconsideração cadastro Cáritas"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import Card from "@/components/Card";

export default {
  components: { Breadcrumb, Card },
  data() {
    return {
      showOverlayer: {
        fig1: false,
      },
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Limites do cadastro corporativo",
          href: "conteudos_tematicos_limitacao_cadastro",
        },
      ],
    };
  },
};
</script>

<style scoped>
.limites_cadastro_background {
  background-image: url("../../assets/conteudos_violacoes_direitos_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

h3 {
  white-space: nowrap;
  text-align: center;
}

.section__mb {
  padding-bottom: 3em;
}

@media (max-width: 600px) {
  h3 {
    white-space: normal;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
